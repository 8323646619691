.hero {
  position: relative;
  margin-top: -10rem;
  margin-left: 5rem;
  margin-right: 5rem;
  min-height: 65rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3rem;
    background-image: url(../img/ui/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;

    @media (max-width: em(575)) {
      border-radius: initial;
    }
  }

  @media (max-width: em(992)) {
    margin-top: 3rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    min-height: 42rem;
  }

  @media (max-width: em(575)) {
    margin-left: initial;
    margin-right: initial;
    min-height: 38rem;
  }

  &__wrapper {
    margin-left: 5rem;
    padding-top: 25rem;
    max-width: 76rem;
    gap: 5rem;

    @media (max-width: em(992)) {
      margin-left: initial;
      padding-top: 10rem;
      max-width: initial;
    }

    @media (max-width: em(575)) {
      gap: 3rem;
    }
  }

  &__title {
    color: var(--clr-default-100);
    font-weight: 600;
  }

  &__description {
    p {
      font-weight: 300;
      color: var(--clr-default-100);
      line-height: 1.55;
    }
  }

  &__button {
    min-width: 20rem;
  }
}
