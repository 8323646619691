.header {
  position: relative;
  z-index: 2;
  margin-top: 10rem;

  @media (max-width: em(1360)) {
   padding-left: 10rem;
   padding-right: 10rem;
  }

  @media (max-width: em(992)) {
    margin-top: 3rem;
    padding-left: initial;
    padding-right: initial;
    z-index: initial;
  }

  &__wrapper {
      gap: 2rem;
    &-menu {
      gap: 3rem;

      @media (max-width: em(575)) {
        gap: 1.5rem;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.2rem solid var(--clr-default-100);
    color: var(--clr-default-100);
    border-radius: 0.8rem;
    min-width: 17rem;
    min-height: 4rem;
    transition: color var(--transition), border var(--transition);

    @media (max-width: em(992)) {
      border: 0.2rem solid var(--clr-default-300);
      color: var(--clr-default-300);
    }

    @media (max-width: em(575)) {
      min-width: 15rem;
    }

    @media (hover) {
      &:hover {
        border: 0.2rem solid var(--clr-default-200);
        color: var(--clr-default-200);
        transition: color var(--transition), border var(--transition);
      }
    }
  }
}
