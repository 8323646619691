.start {
  padding-top: 13rem;

  @media (max-width: em(992)) {
    padding-top: 8rem;
  }

  @media (max-width: em(575)) {
    padding-top: 5rem;
  }

  &__wrapper {
    gap: 10rem;

    @media (max-width: em(992)) {
      gap: 5rem;
    }

    @media (max-width: em(575)) {
      gap: 3rem;
    }
  }

  &__list {
    counter-reset: num;
    list-style-type: none;
    flex-wrap: wrap;
    gap: 4rem;

    @media (max-width: em(992)) {
      flex-direction: column;
      gap: 3rem;
    }
  }

  &__item {
    position: relative;
    max-width: 26rem;
    padding-top: 6rem;

    &::before {
      counter-increment: num;
      content: counter(num);
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--clr-default-100);
      font-size: 1.6rem;
      font-weight: 600;
      width: 3.8rem;
      height: 3.8rem;
      border-radius: 50%;
      background-color: var(--clr-default-300);
      z-index: 2;
    }

    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        top: 1.8rem;
        left: 0;
        width: calc(100% + 4rem);
        height: 0.25rem;
        background-color: var(--clr-default-300);

        @media (max-width: em(992)) {
          display: none;
        }
      }
    }

    &-title {
      margin-bottom: 1.8rem;
      font-size: fluid-font(2rem, 1.8rem);
      font-weight: 600;

      @media (max-width: em(992)) {
        margin-bottom: 1rem;
      }
    }

    &-text {
      line-height: 155%;
    }

    @media (max-width: em(992)) {
      max-width: initial;
      padding-top: 5rem;
    }
  }
}