// <МИКСИНЫ>==================================================================
@import "./settings/mixins";

// </МИКСИНЫ>=================================================================

// ФУНКЦИИ====================================================================
@import "./settings/functions";

// </ФУНКЦИИ>=================================================================

// <Подключаем шрифты>========================================================
@import "./settings/fonts";

// </Подключаем шрифты>=======================================================

// <ОБНУЛЕНИЕ>================================================================
@import "./settings/normalize";

// </ОБНУЛЕНИЕ>===============================================================

// <ПЕРЕМЕННЫЕ>===============================================================
$containerWidth: 123rem;

:root {
  // base
  --font-family: "Montserrat", sans-serif;
  --font-weight: 400;
  --content-width: 120rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;

  // colors
  --clr-default-100: #fff;
  --clr-default-150: #d6d6d6;
  --clr-default-180: #f6f5f4;
  --clr-default-200: #ffee51;
  --clr-default-250: #f5dd03;
  --clr-default-300: #ff9b61;
  --clr-default-350: #e55227;
  --clr-default-900: #000;
}

// </ПЕРЕМЕННЫЕ>==============================================================

// <ОБОЛОЧКИ>================================================================
body {
  font-weight: var(--font-weight);
  font-size: 1.6rem;
  color: var(--clr-default-900);

  &.lock {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    -ms-touch-action: none;
    touch-action: none;
    overscroll-behavior: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background-color: rgb(0 0 0 / 50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition), visibility var(--transition);
  }

  &[data-overlay="true"]::before {
    opacity: 1;
    visibility: visible;
    transition: opacity var(--transition), visibility var(--transition);
  }
}

main {
  flex-grow: 1;
}

.bg__wrapper {
  position: relative;
  overflow: hidden;
  margin-top: 3rem;
  margin-right: 5rem;
  margin-left: 5rem;
  border-radius: 3rem;
  height: 70rem;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: url(../img/ui/bg.png);
    background-position: 0rem;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

// </ОБОЛОЧКИ>===============================================================

// <СЕТКА>===================================================================
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

// Components - UI
@import "./ui";
@import "./stack";
@import "./typography";

// Components - UI

// Components - UI
@import "./components/ui/menu";
@import "./components/ui/logo";
@import "./components/ui/burger";

// Components - UI

// Components - Common
@import "./components/common/header";
@import "./components/common/footer";
@import "./components/common/modal-request";

// Components - Common

// Components - Pages
@import "./components/indexPage/hero";
@import "./components/indexPage/partners";
@import "./components/indexPage/account";
@import "./components/indexPage/start";
@import "./components/indexPage/created";
@import "./components/indexPage/ask";
@import "./components/indexPage/contacts";
@import "./components/policyPage/policy";

// Components - Pages

// </СЕТКА>==================================================================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================
