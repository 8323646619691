.policy {
  margin-top: 10rem;

  &__wrapper {
    gap: 2rem;
  }

  &__titles {
    gap: 0.5rem;
  }

  &__text {
    gap: 1.5rem;

    p {
      line-height: 133%;
    }
  }

  &__list {
    gap: 2rem;
  }

  &__item {
    gap: 1.5rem;

    p {
      line-height: 133%;
    }
  }

  &__content-title {
    margin-bottom: 2rem;
  }

  &__list-disc {
    list-style: disc;
    padding-left: 2rem;

    li {
      line-height: 133%;
    }
  }
}
