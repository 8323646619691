/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: auto 1px;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-family: "Montserrat", sans-serif;
  --font-weight: 400;
  --content-width: 120rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;
  --clr-default-100: #fff;
  --clr-default-150: #d6d6d6;
  --clr-default-180: #f6f5f4;
  --clr-default-200: #ffee51;
  --clr-default-250: #f5dd03;
  --clr-default-300: #ff9b61;
  --clr-default-350: #e55227;
  --clr-default-900: #000;
}

body {
  font-weight: var(--font-weight);
  font-size: 1.6rem;
  color: var(--clr-default-900);
}

body.lock {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  -ms-touch-action: none;
  touch-action: none;
  overscroll-behavior: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

main {
  flex-grow: 1;
}

.bg__wrapper {
  position: relative;
  overflow: hidden;
  margin-top: 3rem;
  margin-right: 5rem;
  margin-left: 5rem;
  border-radius: 3rem;
  height: 70rem;
}

.bg__wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: url(../img/ui/bg.png);
  background-position: 0rem;
  background-size: cover;
  background-repeat: no-repeat;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: var(--clr-default-900);
  font-size: 1.6rem;
  background-color: var(--clr-default-200);
  font-weight: 700;
  transition: background-color var(--transition), box-shadow var(--transition);
}

.button-border {
  border-radius: 3rem;
  min-height: 6rem;
}

.button-square {
  border-radius: 1.2rem;
}

.stack {
  display: flex;
}

.stack.column {
  flex-direction: column;
}

.stack.column-reverse {
  flex-direction: column-reverse;
}

.stack.row {
  flex-direction: row;
}

.stack.row-reverse {
  flex-direction: row-reverse;
}

.stack.justify-flex-start {
  justify-content: flex-start;
}

.stack.justify-center {
  justify-content: center;
}

.stack.justify-flex-end {
  justify-content: flex-end;
}

.stack.justify-space-between {
  justify-content: space-between;
}

.stack.justify-space-around {
  justify-content: space-around;
}

.stack.center {
  align-items: center;
  justify-content: center;
}

.stack.align-start {
  align-items: flex-start;
}

.stack.align-center {
  align-items: center;
}

.stack.align-end {
  align-items: flex-end;
}

.stack.circle-center {
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.stack.wrap {
  flex-wrap: wrap;
}

.stack.no-wrap {
  flex-wrap: nowrap;
}

.stack.wrap-reverse {
  flex-wrap: wrap-reverse;
}

.typography.h1 {
  font-size: clamp(
    2.4rem,
    1.4153846154rem + 3.0769230769vw,
    5.2rem
  );
}

.typography.h2 {
  font-size: clamp(
    2.2rem,
    1.4967032967rem + 2.1978021978vw,
    4.2rem
  );
  font-weight: 700;
}

.typography.h3 {
  font-size: 2.2rem;
}

.typography.h4 {
  font-size: 1.9rem;
}

.typography.h5 {
  font-size: 1.7rem;
}

.typography.h6 {
  font-size: 1.6rem;
}

.typography.p {
  font-size: clamp(
    1.6rem,
    1.5296703297rem + 0.2197802198vw,
    1.8rem
  );
}

.typography.caption {
  font-size: 1.2rem;
}

.typography.w-400 {
  font-weight: 400;
}

.typography.w-500 {
  font-weight: 500;
}

.typography.w-600 {
  font-weight: 600;
}

.typography.success {
  color: var(--clr-status-success);
}

.typography.error {
  color: var(--clr-primary-600);
}

.typography.grey {
  color: var(--clr-default-600);
}

.typography.dark {
  color: var(--clr-default-800);
}

.typography.white {
  color: var(--clr-default-100);
}

.typography.uppercase {
  text-transform: uppercase;
}

.typography.center {
  text-align: center;
}

.typography.lh-150 {
  line-height: 150%;
}

.typography .link {
  color: var(--clr-secondary-900);
  transition: color var(--transition);
}

.typography .link.focus-visible {
  outline: none;
  color: var(--clr-secondary-400);
}

.menu__list {
  display: flex;
  align-items: center;
}

.menu__item a {
  color: var(--clr-default-100);
  font-size: 1.9rem;
  transition: color var(--transition);
}

.menu__item:not(:last-child) {
  margin-right: 1.5rem;
}

.menu__item--burger {
  display: none;
}

.logo {
  max-width: 26rem;
}

.burger {
  display: none;
}

.header {
  position: relative;
  z-index: 2;
  margin-top: 10rem;
}

.header__wrapper {
  gap: 2rem;
}

.header__wrapper-menu {
  gap: 3rem;
}

.header__button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.2rem solid var(--clr-default-100);
  color: var(--clr-default-100);
  border-radius: 0.8rem;
  min-width: 17rem;
  min-height: 4rem;
  transition: color var(--transition), border var(--transition);
}

.footer {
  padding-top: 10rem;
  padding-bottom: 5rem;
}

.footer__wrapper {
  gap: 3rem;
}

.footer__wrapper p {
  color: var(--clr-default-350);
  letter-spacing: 0.1rem;
  font-size: 1.4rem;
  line-height: 140%;
  opacity: 0.7;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.modal__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  min-height: 100%;
}

.modal__content {
  position: relative;
  padding: 2rem;
  width: 100%;
  max-width: 80rem;
  background-color: var(--clr-default-100);
  opacity: 0;
  transform: translateY(-100%);
  transition: transform var(--transition), opacity var(--transition);
}

.modal__close {
  position: absolute;
  right: 0.2rem;
  top: 0.2rem;
  width: 2rem;
  height: 2rem;
}

.modal__close::after,
.modal__close::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 0.2rem;
  background-color: var(--clr-default-900);
}

.modal__close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.modal__close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.modal.show {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

.modal.show .modal__content {
  opacity: 1;
  transform: translateY(0);
  transition: transform var(--transition), opacity var(--transition);
}

.modal-request .modal__content {
  max-width: 45rem;
  border-radius: 0.8rem;
  padding: 4rem;
}

.modal-request .modal__close {
  right: 1rem;
  top: 1rem;
}

.modal-request__body {
  gap: 3rem;
}

.modal-request__title {
  text-align: center;
  font-size: clamp(
    2.2rem,
    2.1296703297rem + 0.2197802198vw,
    2.4rem
  );
  line-height: 123%;
  font-weight: 700;
}

.modal-request__form {
  gap: 1rem;
}

.modal-request__input {
  transition: border var(--transition);
}

.modal-request__input input {
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  color: var(--clr-default-900);
  background-color: var(--clr-default-180);
}

.modal-request__input input::-webkit-outer-spin-button,
.modal-request__input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.modal-request__input input::placeholder {
  color: var(--clr-default-150);
}

.modal-request__input input.focus-visible {
  outline: none;
}

.modal-request__input input:focus-within {
  border: 0.1rem solid var(--clr-default-300);
}

.modal-request__input input[type=number] {
  -moz-appearance: textfield;
}

.modal-request__textarea {
  background-color: var(--clr-default-180);
}

.modal-request__textarea textarea {
  overflow: auto;
  border: none;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.2rem;
  width: 100%;
  height: 100%;
  resize: none;
  transition: border var(--transition);
}

.modal-request__textarea textarea::placeholder {
  color: var(--clr-default-150);
}

.modal-request__textarea textarea.focus-visible {
  outline: none;
}

.modal-request__textarea textarea:focus-within {
  border: 0.1rem solid var(--clr-default-300);
}

.modal-request__button {
  width: 100%;
  min-height: 6rem;
}

.modal-request .just-validate-error-label {
  padding-top: 1rem;
  font-size: 1.2rem;
}

.modal-request .ask__form-status--error,
.modal-request .ask__form-status--success {
  display: none;
}

.modal-request .ask__form-status--error,
.modal-request .ask__form-status--success {
  grid-column: 1/3;
  padding-top: 1rem;
  text-align: center;
}

.modal-request .ask__form-status--error {
  color: red;
}

.modal-request .ask__form-status--success {
  color: green;
}

.modal-request .loader {
  display: none;
  grid-column: 1/3;
}

.modal-request .loader svg {
  width: 6rem;
  height: max-content;
}

.hero {
  position: relative;
  margin-top: -10rem;
  margin-left: 5rem;
  margin-right: 5rem;
  min-height: 65rem;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 3rem;
  background-image: url(../img/ui/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.hero__wrapper {
  margin-left: 5rem;
  padding-top: 25rem;
  max-width: 76rem;
  gap: 5rem;
}

.hero__title {
  color: var(--clr-default-100);
  font-weight: 600;
}

.hero__description p {
  font-weight: 300;
  color: var(--clr-default-100);
  line-height: 1.55;
}

.hero__button {
  min-width: 20rem;
}

.partners {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.partners__list {
  flex-wrap: wrap;
  gap: 2rem;
}

.partners__item {
  margin-left: auto;
  margin-right: auto;
  max-width: 15rem;
  opacity: 0.5;
  transition: opacity var(--transition);
}

.account {
  position: relative;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.account::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/ui/bg.png);
  background-position: 0rem;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.account__wrapper {
  gap: 7rem;
}

.account__text {
  gap: 3rem;
  color: var(--clr-default-100);
}

.account__description p {
  text-align: center;
  font-size: clamp(
    1.8rem,
    1.589010989rem + 0.6593406593vw,
    2.4rem
  );
  line-height: 150%;
}

.account__list {
  justify-content: center;
  gap: 6rem;
}

.account__item {
  border: 0.8rem solid var(--clr-default-100);
  border-radius: 3rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 3rem;
  padding-right: 3rem;
  max-width: 33rem;
  min-height: 38rem;
  gap: 2rem;
}

.account__item-top {
  gap: 3rem;
}

.account__item-title {
  text-align: center;
  color: var(--clr-default-100);
  font-weight: 700;
  line-height: 155%;
  font-size: clamp(
    2.2rem,
    1.7076923077rem + 1.5384615385vw,
    3.6rem
  );
}

.account__item-text p {
  text-align: center;
  color: var(--clr-default-100);
  line-height: 155%;
}

.account__item-button {
  margin-top: auto;
  min-width: 18rem;
}

.start {
  padding-top: 13rem;
}

.start__wrapper {
  gap: 10rem;
}

.start__list {
  counter-reset: num;
  list-style-type: none;
  flex-wrap: wrap;
  gap: 4rem;
}

.start__item {
  position: relative;
  max-width: 26rem;
  padding-top: 6rem;
}

.start__item::before {
  counter-increment: num;
  content: counter(num);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--clr-default-100);
  font-size: 1.6rem;
  font-weight: 600;
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
  background-color: var(--clr-default-300);
  z-index: 2;
}

.start__item:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 1.8rem;
  left: 0;
  width: calc(100% + 4rem);
  height: 0.25rem;
  background-color: var(--clr-default-300);
}

.start__item-title {
  margin-bottom: 1.8rem;
  font-size: clamp(
    1.8rem,
    1.7296703297rem + 0.2197802198vw,
    2rem
  );
  font-weight: 600;
}

.start__item-text {
  line-height: 155%;
}

.created {
  padding-top: 18rem;
}

.created__wrapper {
  gap: 10rem;
}

.created__title {
  max-width: 65rem;
}

.created__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7rem 4rem;
}

.created__item {
  gap: 3rem;
}

.created__item-image {
  max-width: 8rem;
}

.created__item-content {
  gap: 2rem;
}

.created__button {
  min-width: 30rem;
  margin-top: auto;
  min-height: 10rem;
}

.ask {
  padding-top: 15rem;
}

.ask__wrapper {
  gap: 7rem;
}

.ask__text {
  gap: 2.5rem;
}

.ask__title {
  font-size: clamp(
    2rem,
    1.578021978rem + 1.3186813187vw,
    3.2rem
  );
  font-weight: 700;
  max-width: 66rem;
  line-height: 135%;
}

.ask__description p {
  line-height: 135%;
}

.ask__content {
  border: 1.5rem solid var(--clr-default-300);
  border-radius: 3rem;
  padding-top: 5rem;
  padding-right: 7rem;
  padding-left: 6rem;
  padding-bottom: 5rem;
  gap: 2.5rem;
}

.ask__content-title {
  font-size: clamp(
    2rem,
    1.2263736264rem + 2.4175824176vw,
    4.2rem
  );
  font-weight: 600;
}

.ask__form {
  justify-content: space-between;
  gap: 3rem;
}

.ask__form-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem 5rem;
  max-width: 81rem;
  width: 100%;
}

.ask__input {
  border-bottom: 0.1rem solid var(--clr-default-150);
  transition: border-bottom var(--transition);
}

.ask__input input {
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  color: var(--clr-default-900);
  background-color: transparent;
}

.ask__input input::placeholder {
  color: var(--clr-default-150);
}

.ask__input input::-webkit-outer-spin-button,
.ask__input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.ask__input input.focus-visible {
  outline: none;
}

.ask__input:focus-within {
  border-bottom: 0.1rem solid var(--clr-default-900);
}

.ask__input input[type=number] {
  -moz-appearance: textfield;
}

.ask__input .just-validate-error-label {
  padding-top: 1rem;
  color: green;
  font-size: 1.2rem;
}

.ask__textarea {
  border-bottom: 0.1rem solid var(--clr-default-150);
  transition: border-bottom var(--transition);
}

.ask__textarea textarea {
  overflow: auto;
  border: none;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
  height: 100%;
  resize: none;
}

.ask__textarea textarea::placeholder {
  color: var(--clr-default-150);
}

.ask__textarea textarea.focus-visible {
  outline: none;
}

.ask__textarea:focus-within {
  border-bottom: 0.1rem solid var(--clr-default-900);
}

.ask__button {
  height: 10rem;
  min-width: 16rem;
  margin-top: auto;
}

.ask .loader {
  display: none;
  grid-column: 1/3;
}

.ask .loader svg {
  width: 6rem;
  height: max-content;
}

.ask__form-status--error,
.ask .ask__form-status--success {
  display: none;
}

.ask__form-status--error,
.ask__form-status--success {
  grid-column: 1/3;
  padding-top: 1rem;
  text-align: center;
  font-weight: 600;
}

.ask__form-status--error {
  color: red;
}

.ask__form-status--success {
  color: green;
}

.contacts {
  padding-top: 13rem;
}

.contacts__wrapper {
  gap: 4rem;
}

.contacts__text {
  gap: 4.5rem;
}

.contacts__subtitle {
  font-size: clamp(
    1.8rem,
    1.5186813187rem + 0.8791208791vw,
    2.6rem
  );
  font-weight: 300;
}

.contacts__list {
  gap: 2.5rem;
}

.contacts__item a,
.contacts__item p {
  line-height: 155%;
  font-size: 1.8rem;
}

.contacts__item--mail a {
  color: var(--clr-default-900);
  transition: color var(--transition);
}

.contacts__item--policy a {
  color: var(--clr-default-300);
  transition: color var(--transition);
}

.policy {
  margin-top: 10rem;
}

.policy__wrapper {
  gap: 2rem;
}

.policy__titles {
  gap: 0.5rem;
}

.policy__text {
  gap: 1.5rem;
}

.policy__text p {
  line-height: 133%;
}

.policy__list {
  gap: 2rem;
}

.policy__item {
  gap: 1.5rem;
}

.policy__item p {
  line-height: 133%;
}

.policy__content-title {
  margin-bottom: 2rem;
}

.policy__list-disc {
  list-style: disc;
  padding-left: 2rem;
}

.policy__list-disc li {
  line-height: 133%;
}

@media (max-width: 85em) {
  .header {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}

@media (max-width: 62em) {
  .menu__list {
    flex-direction: column;
    margin: auto;
    padding-block: 4rem;
  }

  .menu__item a {
    font-weight: 600;
  }

  .menu__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 3rem;
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: rgba(6, 8, 3, 0.8);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    transition: right var(--transition);
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: rgb(255, 166, 87);
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }

  .header {
    margin-top: 3rem;
    padding-left: initial;
    padding-right: initial;
    z-index: initial;
  }

  .header__button {
    border: 0.2rem solid var(--clr-default-300);
    color: var(--clr-default-300);
  }

  .footer {
    padding-top: 8rem;
    padding-bottom: 3rem;
  }

  .hero {
    margin-top: 3rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    min-height: 42rem;
  }

  .hero__wrapper {
    margin-left: initial;
    padding-top: 10rem;
    max-width: initial;
  }

  .account {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .account__wrapper {
    gap: 5rem;
  }

  .account__text {
    gap: 1rem;
  }

  .account__list {
    justify-content: space-between;
  }

  .account__item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .start {
    padding-top: 8rem;
  }

  .start__wrapper {
    gap: 5rem;
  }

  .start__list {
    flex-direction: column;
    gap: 3rem;
  }

  .start__item:not(:last-child)::after {
    display: none;
  }

  .start__item-title {
    margin-bottom: 1rem;
  }

  .start__item {
    max-width: initial;
    padding-top: 5rem;
  }

  .created {
    padding-top: 8rem;
  }

  .created__wrapper {
    gap: 5rem;
  }

  .created__list {
    gap: 2rem;
  }

  .ask {
    padding-top: 8rem;
  }

  .ask__wrapper {
    gap: 4rem;
  }

  .ask__title {
    max-width: initial;
  }

  .ask__content {
    padding: 3rem;
  }

  .ask__form {
    flex-direction: column;
  }

  .ask__form-wrapper {
    gap: 2.5rem;
  }

  .ask__input input {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .ask__textarea textarea {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .ask__button {
    height: 5rem;
  }

  .contacts {
    padding-top: 10rem;
  }
}

@media (max-width: 47.9375em) {
  .account__list {
    flex-direction: column;
    gap: 3rem;
  }

  .account__item-top {
    gap: 1rem;
  }

  .account__item-button {
    min-width: 100%;
    min-height: 5rem;
  }

  .account__item {
    max-width: initial;
    min-height: initial;
    border: 0.4rem solid var(--clr-default-100);
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .created__wrapper {
    gap: 3rem;
  }

  .created__list {
    grid-template-columns: 1fr;
  }

  .created__item-image {
    max-width: 5rem;
  }

  .created__item-content {
    gap: 1rem;
  }

  .created__item {
    gap: 1.5rem;
  }

  .created__button {
    min-height: 6rem;
    min-width: 100%;
  }

  .ask__content {
    border: 0.5rem solid var(--clr-default-300);
    padding: 2rem;
  }
}

@media (max-width: 35.9375em) {
  .button-border {
    min-height: 5rem;
  }

  .menu__item--burger {
    display: block;
  }

  .header__wrapper-menu {
    gap: 1.5rem;
  }

  .header__button {
    min-width: 15rem;
  }

  .footer {
    padding-top: 6rem;
  }

  .footer__wrapper {
    gap: 1.5rem;
  }

  .modal-request .modal__content {
    padding: 2rem;
  }

  .hero::before {
    border-radius: initial;
  }

  .hero {
    margin-left: initial;
    margin-right: initial;
    min-height: 38rem;
  }

  .hero__wrapper {
    gap: 3rem;
  }

  .partners {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .partners__item {
    max-width: 10rem;
  }

  .account__wrapper {
    gap: 3rem;
  }

  .start {
    padding-top: 5rem;
  }

  .start__wrapper {
    gap: 3rem;
  }

  .ask__form-wrapper {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .ask__button {
    min-width: 100%;
  }

  .contacts {
    padding-top: 5rem;
  }

  .contacts__wrapper {
    gap: 2.5rem;
  }

  .contacts__text {
    gap: 3rem;
  }

  .contacts__list {
    gap: 2rem;
  }
}

@media (hover) {
  .button-border:hover {
    background-color: var(--clr-default-250);
    box-shadow: 5px 7px 34px -2px rgba(255, 238, 81, 0.48);
    -webkit-box-shadow: 5px 7px 34px -2px rgba(255, 238, 81, 0.48);
    -moz-box-shadow: 5px 7px 34px -2px rgba(255, 238, 81, 0.48);
    transition: background-color var(--transition), box-shadow var(--transition);
  }

  .button-square:hover {
    background-color: var(--clr-default-250);
    box-shadow: 5px 7px 34px -2px rgba(255, 238, 81, 0.48);
    -webkit-box-shadow: 5px 7px 34px -2px rgba(255, 238, 81, 0.48);
    -moz-box-shadow: 5px 7px 34px -2px rgba(255, 238, 81, 0.48);
    transition: background-color var(--transition), box-shadow var(--transition);
  }

  .typography .link:hover {
    color: var(--clr-secondary-400);
  }

  .menu__item:hover a {
    color: var(--clr-default-200);
    transition: color var(--transition);
  }

  .header__button:hover {
    border: 0.2rem solid var(--clr-default-200);
    color: var(--clr-default-200);
    transition: color var(--transition), border var(--transition);
  }

  .partners__item:hover {
    opacity: 1;
    transition: opacity var(--transition);
  }

  .contacts__item--mail a:hover {
    color: var(--clr-default-350);
    transition: color var(--transition);
  }

  .contacts__item--policy a:hover {
    color: var(--clr-default-350);
    transition: color var(--transition);
  }
}