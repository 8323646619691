.ask {
  padding-top: 15rem;

  @media (max-width: em(992)) {
    padding-top: 8rem;
  }

  &__wrapper {
    gap: 7rem;

    @media (max-width: em(992)) {
      gap: 4rem;
    }
  }

  &__text {
    gap: 2.5rem;
  }

  &__title {
    font-size: fluid-font(3.2rem, 2rem);
    font-weight: 700;
    max-width: 66rem;
    line-height: 135%;

    @media (max-width: em(992)) {
      max-width: initial;
    }
  }

  &__description {
    p {
      line-height: 135%;
    }
  }

  &__content {
    border: 1.5rem solid var(--clr-default-300);
    border-radius: 3rem;
    padding-top: 5rem;
    padding-right: 7rem;
    padding-left: 6rem;
    padding-bottom: 5rem;
    gap: 2.5rem;

    &-title {
      font-size: fluid-font(4.2rem, 2rem);
      font-weight: 600;
    }

    @media (max-width: em(992)) {
      padding: 3rem;
    }

    @media (max-width: em(767)) {
      border: 0.5rem solid var(--clr-default-300);
      padding: 2rem;
    }
  }

  &__form {
    justify-content: space-between;
    gap: 3rem;
    // align-items: center;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__form-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem 5rem;
    max-width: 81rem;
    width: 100%;

    @media (max-width: em(992)) {
      gap: 2.5rem;
    }

    @media (max-width: em(575)) {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }
  }

  &__input {
    border-bottom: 0.1rem solid var(--clr-default-150);
    transition: border-bottom var(--transition);

    input {
      display: flex;
      align-items: center;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      width: 100%;
      height: 100%;
      font-size: 1.6rem;
      color: var(--clr-default-900);
      background-color: transparent;

      &::placeholder {
        color: var(--clr-default-150);
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
          -webkit-appearance: none;
      }

      &.focus-visible {
        outline: none;
      }

      @media (max-width: em(992)) {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    &:focus-within {
      border-bottom: 0.1rem solid var(--clr-default-900);
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    .just-validate-error-label {
      padding-top: 1rem;
      color: green;
      font-size: 1.2rem;
    }
  }

  &__textarea {
    border-bottom: 0.1rem solid var(--clr-default-150);
    transition: border-bottom var(--transition);

    textarea {
      overflow: auto;
      border: none;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      width: 100%;
      height: 100%;
      resize: none;

      &::placeholder {
        color: var(--clr-default-150);
      }

      &.focus-visible {
        outline: none;
      }

      @media (max-width: em(992)) {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    &:focus-within {
      border-bottom: 0.1rem solid var(--clr-default-900);
    }
  }

  &__button {
    height: 10rem;
    min-width: 16rem;
    margin-top: auto;

    @media (max-width: em(992)) {
      height: 5rem;
    }

    @media (max-width: em(575)) {
      min-width: 100%;
    }
  }

  .loader {
    display: none;
    grid-column: 1/3;
    svg {
      width: 6rem;
      height: max-content;
    }
  }

  &__form-status--error, .ask__form-status--success {
    display: none;
  }

  &__form-status {
    &--error, &--success {
      grid-column: 1/3;
      padding-top: 1rem;
      text-align: center;
      font-weight: 600;
    }

    &--error {
      color: red;
    }

    &--success {
      color: green;
    }
  }
}