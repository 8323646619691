$burgerMQ: em(992);

.menu {
  &__list {
    display: flex;
    align-items: center;

    @media (max-width: $burgerMQ) {
      flex-direction: column;
      margin: auto;
      padding-block: 4rem;
    }
  }

  &__item {
    a {
      color: var(--clr-default-100);
      font-size: 1.9rem;
      transition: color var(--transition);

      @media (max-width: $burgerMQ) {
        font-weight: 600;
      }
    }

    &:not(:last-child) {
      margin-right: 1.5rem;

      @media (max-width: $burgerMQ) {
        margin-right: 0;
        margin-bottom: 3rem;
      }
    }

    &--burger {
      display: none;

      @media (max-width: em(575)) {
        display: block;
      }
    }

    @media (hover) {
      &:hover {
        a {
          color: var(--clr-default-200);
          transition: color var(--transition);
        }
      }
    }
  }

  @media (max-width: $burgerMQ) {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: rgba(6, 8, 3, 0.8);
    transition: right var(--transition);

    &--active {
      right: 0;
      transition: right var(--transition);
    }
  }
}
