$overlayBgColor: rgba(0, 0, 0, 0.8);

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: $overlayBgColor;
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);

  &__wrapper {
    @include flex-all-center;

    padding: 2.5rem;
    min-height: 100%;
  }

  &__content {
    position: relative;
    padding: 2rem;
    width: 100%;
    max-width: 80rem;
    background-color: var(--clr-default-100);
    opacity: 0;
    transform: translateY(-100%);
    transition: transform var(--transition), opacity var(--transition);
  }

  &__close {
    position: absolute;
    right: 0.2rem;
    top: 0.2rem;
    width: 2rem;
    height: 2rem;

    &::after,
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 0.2rem;
      background-color: var(--clr-default-900);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
    transition: opacity var(--transition), visibility var(--transition);

    .modal__content {
      opacity: 1;
      transform: translateY(0);
      transition: transform var(--transition), opacity var(--transition);
    }
  }

  &-request {
    .modal__content {
      max-width: 45rem;
      border-radius: 0.8rem;
      padding: 4rem;

      @media (max-width: em(575)) {
        padding: 2rem;
      }
    }

    .modal__close {
      right: 1rem;
      top: 1rem;
    }

    &__body {
      gap: 3rem;
    }

    &__title {
      text-align: center;
      font-size: fluid-font(2.4rem, 2.2rem);
      line-height: 123%;
      font-weight: 700;
    }

    &__form {
      gap: 1rem;
    }

    &__input {
      transition: border var(--transition);

      input {
        display: flex;
        align-items: center;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-right: 1.2rem;
        padding-left: 1.2rem;
        width: 100%;
        height: 100%;
        font-size: 1.6rem;
        color: var(--clr-default-900);
        background-color: var(--clr-default-180);

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        &::placeholder {
          color: var(--clr-default-150);
        }

        &.focus-visible {
          outline: none;
        }

        &:focus-within {
          border: 0.1rem solid var(--clr-default-300);
        }
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }
    }

    &__textarea {
      background-color: var(--clr-default-180);
      
      textarea {
        overflow: auto;
        border: none;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 1.2rem;
        width: 100%;
        height: 100%;
        resize: none;
        transition: border var(--transition);

        &::placeholder {
          color: var(--clr-default-150);
        }
        
        &.focus-visible {
          outline: none;
        }

        &:focus-within {
          border: 0.1rem solid var(--clr-default-300);
        }
      }
    }

    &__button {
      width: 100%;
      min-height: 6rem;
    }

    .just-validate-error-label {
      padding-top: 1rem;
      font-size: 1.2rem;
    }

    .ask__form-status--error, .ask__form-status--success {
      display: none;
    }
  
    .ask__form-status {
      &--error, &--success {
        grid-column: 1/3;
        padding-top: 1rem;
        text-align: center;
      }
  
      &--error {
        color: red;
      }
  
      &--success {
        color: green;
      }
    }

    .loader {
      display: none;
      grid-column: 1/3;
      svg {
        width: 6rem;
        height: max-content;
      }
    }
  }
}
