.account {
  position: relative;
  padding-top: 10rem;
  padding-bottom: 10rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../img/ui/bg.png);
    background-position: 0rem;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  @media (max-width: em(992)) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  &__wrapper {
    gap: 7rem;

    @media (max-width: em(992)) {
      gap: 5rem;
    }

    @media (max-width: em(575)) {
      gap: 3rem;
    }
  }

  &__text {
    gap: 3rem;
    color: var(--clr-default-100);

    @media (max-width: em(992)) {
      gap: 1rem;
    }
  }

  &__description {
    p {
      text-align: center;
      font-size: fluid-font(2.4rem, 1.8rem);
      line-height: 150%;
    }
  }

  &__list {
    justify-content: center;
    gap: 6rem;

    @media (max-width: em(992)) {
      justify-content: space-between;
    }

    @media (max-width: em(767)) {
      flex-direction: column;
      gap: 3rem;
    }
  }

  &__item {
    border: 0.8rem solid var(--clr-default-100);
    border-radius: 3rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: 33rem;
    min-height: 38rem;
    gap: 2rem;

    &-top {
      gap: 3rem;

      @media (max-width: em(767)) {
        gap: 1rem;
      }
    }

    &-title {
      text-align: center;
      color: var(--clr-default-100);
      font-weight: 700;
      line-height: 155%;
      font-size: fluid-font(3.6rem, 2.2rem);
    }

    &-text {
      p {
        text-align: center;
        color: var(--clr-default-100);
        line-height: 155%;
      }
    }

    &-button {
      margin-top: auto;
      min-width: 18rem;

      @media (max-width: em(767)) {
        min-width: 100%;
        min-height: 5rem;
      }
    }

    @media (max-width: em(992)) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    @media (max-width: em(767)) {
      max-width: initial;
      min-height: initial;
      border: 0.4rem solid var(--clr-default-100);
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }
}