.created {
  padding-top: 18rem;

  @media (max-width: em(992)) {
    padding-top: 8rem;
  }

  &__wrapper {
    gap: 10rem;

    @media (max-width: em(992)) {
      gap: 5rem;
    }

    @media (max-width: em(767)) {
      gap: 3rem;
    }
  }

  &__title {
    max-width: 65rem;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 7rem 4rem;

    @media (max-width: em(992)) {
      gap: 2rem;
    }

    @media (max-width: em(767)) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    gap: 3rem;

    &-image {
      max-width: 8rem;

      @media (max-width: em(767)) {
        max-width: 5rem;
      }
    }

    &-content {
      gap: 2rem;

      @media (max-width: em(767)) {
        gap: 1rem;
      }
    }

    @media (max-width: em(767)) {
      gap: 1.5rem;
    }
  }

  &__button {
    min-width: 30rem;
    margin-top: auto;
    min-height: 10rem;

    @media (max-width: em(767)) {
      min-height: 6rem;
      min-width: 100%;
    }
  }
}