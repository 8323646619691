.contacts {
  padding-top: 13rem;

  @media (max-width: em(992)) {
    padding-top: 10rem;
  }

  @media (max-width: em(575)) {
    padding-top: 5rem;
  }

  &__wrapper {
    gap: 4rem;

    @media (max-width: em(575)) {
      gap: 2.5rem;
    }
  }

  &__text {
    gap: 4.5rem;

    @media (max-width: em(575)) {
      gap: 3rem;
    }
  }

  &__subtitle {
    font-size: fluid-font(2.6rem, 1.8rem);
    font-weight: 300;
  }

  &__list {
    gap: 2.5rem;

    @media (max-width: em(575)) {
      gap: 2rem;
    }
  }

  &__item {
    a, p {
      line-height: 155%;
      font-size: 1.8rem;
    }

    &--mail {
      a {
        color: var(--clr-default-900);
        transition: color var(--transition);

        @media (hover) {
          &:hover {
            color: var(--clr-default-350);
            transition: color var(--transition);
          }
        }
      }
    }

    &--policy {
      a {
        color: var(--clr-default-300);
        transition: color var(--transition);

        @media (hover) {
          &:hover {
            color: var(--clr-default-350);
            transition: color var(--transition);
          }
        }
      }
    }
  }
}