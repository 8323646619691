.partners {
  padding-top: 7rem;
  padding-bottom: 7rem;

  @media (max-width: em(575)) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  &__list {
    flex-wrap: wrap;
    gap: 2rem;
  }

  &__item {
    margin-left: auto;
    margin-right: auto;
    max-width: 15rem;
    opacity: 0.5;
    transition: opacity var(--transition);

    @media (hover) {
      &:hover {
        opacity: 1;
        transition: opacity var(--transition);
      }
    }

    @media (max-width: em(575)) {
      max-width: 10rem;
    }
  }
}