.footer {
  padding-top: 10rem;
  padding-bottom: 5rem;

  @media (max-width: em(992)) {
    padding-top: 8rem;
    padding-bottom: 3rem;
  }

  @media (max-width: em(575)) {
    padding-top: 6rem;
  }

  &__wrapper {
    gap: 3rem;

    p {
      color: var(--clr-default-350);
      letter-spacing: 0.1rem;
      font-size: 1.4rem;
      line-height: 140%;
      opacity: 0.7;
    }

    @media (max-width: em(575)) {
      gap: 1.5rem;
    }
  }
}
