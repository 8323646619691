.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: var(--clr-default-900);
  font-size: 1.6rem;
  background-color: var(--clr-default-200);
  font-weight: 700;
  transition: background-color var(--transition), box-shadow var(--transition);

  &-border {
    border-radius: 3rem;
    min-height: 6rem;

    @media (max-width: em(575)) {
      min-height: 5rem;
    }

    @media (hover) {
      &:hover {
        background-color: var(--clr-default-250);
        box-shadow: 5px 7px 34px -2px rgba(255, 238, 81, 0.48);
        -webkit-box-shadow: 5px 7px 34px -2px rgba(255, 238, 81, 0.48);
        -moz-box-shadow: 5px 7px 34px -2px rgba(255, 238, 81, 0.48);
        transition: background-color var(--transition), box-shadow var(--transition);
      }
    }
  }

  &-square {
    border-radius: 1.2rem;

    @media (hover) {
      &:hover {
        background-color: var(--clr-default-250);
        box-shadow: 5px 7px 34px -2px rgba(255, 238, 81, 0.48);
        -webkit-box-shadow: 5px 7px 34px -2px rgba(255, 238, 81, 0.48);
        -moz-box-shadow: 5px 7px 34px -2px rgba(255, 238, 81, 0.48);
        transition: background-color var(--transition), box-shadow var(--transition);
      }
    }
  }
}
